import { transformUrl } from '@@/bits/location'
import type { LibraryAnalytics, TenantAnalytics } from '@@/types'

type Analytics = TenantAnalytics | LibraryAnalytics

export const parseAnalytics = (analytics: Analytics, darkMode: boolean): Analytics => {
  const parsedAnalytics = { ...analytics }
  for (const key in parsedAnalytics) {
    let embedLink = parsedAnalytics[key]
    if (embedLink !== null) {
      const url = new URL(embedLink)
      /**
       * Metabase receives the appearance parameters
       * through the hash instead of the URL query params.
       */
      url.hash = new URLSearchParams({
        bordered: 'false',
        titled: 'false',
        theme: darkMode ? 'night' : 'transparent',
      }).toString()
      embedLink = url.toString()
    }
    parsedAnalytics[key] = embedLink
  }
  return parsedAnalytics
}

/**
 * Builds a CSV download URL based on a Metabase embed URL.
 *
 * @param embedUrl The embed URL.
 * @param datasetId The Metabase dataset ID.
 * @param cardId The Metabase dash ID.
 * @returns The CSV download URL.
 */
export const getAnalyticsCsvDownloadUrl = (embedUrl: string, datasetId: number, cardId: number): string => {
  const url = new URL(embedUrl)
  return transformUrl(embedUrl, {
    path: `api${url.pathname}/dashcard/${datasetId}/card/${cardId}/csv?parameters=%7B%7D&format_rows=true`,
  })
}
