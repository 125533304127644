// @file Library security store
import { ALERT_ICON } from '@@/bits/confirmation_dialog'
import { captureFetchException } from '@@/bits/error_tracker'
import { isAppUsing } from '@@/bits/flip'
import { __ } from '@@/bits/intl'
import { NATIVE_HOST } from '@@/bits/url'
import {
  GoogleAppLicensingSettings as GoogleAppLicensingAPI,
  LibraryOauthSettings as LibraryOauthMethodAPI,
  LibraryUserEmailDomain as LibraryUserEmailDomainAPI,
  Saml as SamlApi,
} from '@@/dashboard/padlet_api'
import { ApiErrorCode, HttpCode, SnackbarNotificationType } from '@@/enums'
import { useDashboardSettingsStore } from '@@/pinia/dashboard_settings'
import { useGlobalConfirmationDialogStore } from '@@/pinia/global_confirmation_dialog'
import { useGlobalInputDialogStore } from '@@/pinia/global_input_dialog'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import { useSettingsNavigationStore } from '@@/pinia/settings_navigation'
import type {
  GoogleAppLicensingCreateResponse,
  GoogleAppLicensingSettingsResponse,
  Id,
  JsonApiData,
  LibraryOauthSettings,
  LibraryUserEmailDomain,
  SamlConnectionApiResponse,
} from '@@/types'
import { LibraryOauthProvider } from '@@/types'
import type { JsonAPIResource } from '@padlet/arvo'
import { FetchError } from '@padlet/fetch'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

enum LibrarySecurityStatus {
  Loading = 'Loading',
  Completed = 'Completed',
  Errored = 'Errored',
}

export enum InputStatus {
  Editing,
  Loading,
  Viewing,
  Errored,
}

export const useSettingsLibrarySecurityStore = defineStore('settingsLibrarySecurity', () => {
  const globalSnackbarStore = useGlobalSnackbarStore()
  const settingsNavigationStore = useSettingsNavigationStore()
  const globalInputDialogStore = useGlobalInputDialogStore()
  const globalConfirmationDialogStore = useGlobalConfirmationDialogStore()

  // State
  const libraryUserEmailDomains = ref<LibraryUserEmailDomain[]>([])
  const libraryUserEmailDomainStatus = ref(LibrarySecurityStatus.Loading)

  const LibraryOauthSettings = ref<LibraryOauthSettings | null>(null)
  const libraryOauthMethodsStatus = ref(LibrarySecurityStatus.Loading)

  const googleAppLicenseData = ref<GoogleAppLicensingCreateResponse>({ orderId: '', redeemUri: '' })
  const isLibraryUsingGoogleAppLicensing = ref(false)
  const isGoogleAppLicenseLoading = ref(false)

  // TODO: add Clever and Classlink SSO ENG-14288,  ENG-14289
  const ALLOWED_OAUTH_METHODS = [
    LibraryOauthProvider.GOOGLE,
    LibraryOauthProvider.MICROSOFT,
    LibraryOauthProvider.APPLE,
    LibraryOauthProvider.PASSWORD,
    LibraryOauthProvider.CLEVER,
    LibraryOauthProvider.CLASSLINK,
  ]

  // Getters
  const isLoadingLibrarySecurity = computed(() => libraryUserEmailDomainStatus.value === LibrarySecurityStatus.Loading)
  const libraryId = computed(() => settingsNavigationStore.currentLibraryId)
  const libraryName = computed(() => settingsNavigationStore.currentLibrary?.name)
  const libraryOauthMethods = computed(() =>
    LibraryOauthSettings.value?.oauth.filter((method) => ALLOWED_OAUTH_METHODS.includes(method.name)),
  )
  const canDisableOauthMethods = computed(() => {
    const enabledMethods = libraryOauthMethods.value?.filter((method) => method.enabled) ?? []
    return enabledMethods.length > 1
  })
  const cleverDistrictId = computed(() => LibraryOauthSettings.value?.cleverDistrictId)
  const classlinkTenantId = computed(() => LibraryOauthSettings.value?.classlinkTenantId)
  const xLibraryUserEmailDomains = computed(() => libraryUserEmailDomains.value.length > 0)

  // Actions
  async function initialize(): Promise<void> {
    if (libraryId.value === null) {
      void globalSnackbarStore.setSnackbar({
        message: __('Error initializing state'),
        notificationType: SnackbarNotificationType.error,
      })
      return
    }
    await fetchLibraryUserEmailDomain(libraryId.value)

    if (isAppUsing('schoolLibrarySSO')) {
      await fetchLibraryOauthSettings(libraryId.value)
    }

    await fetchLibraryGoogleAppLicensing(libraryId.value)

    // SAML login
    if (isAppUsing('librarySamlLogin')) {
      void fetchSamlLoginInfo()
    }
  }

  async function fetchLibraryUserEmailDomain(libraryId: number): Promise<void> {
    try {
      libraryUserEmailDomainStatus.value = LibrarySecurityStatus.Loading
      const response = await LibraryUserEmailDomainAPI.fetch({ libraryId })
      const emailDomains = (response.data as Array<JsonAPIResource<LibraryUserEmailDomain>>)?.map(
        (emailDomain) => emailDomain.attributes,
      )
      libraryUserEmailDomains.value = emailDomains
      libraryUserEmailDomainStatus.value = LibrarySecurityStatus.Completed
    } catch (e) {
      captureFetchException(e, { source: 'DashboardSettingsLibraryFetchLibraryUserEmailDomain' })
      libraryUserEmailDomainStatus.value = LibrarySecurityStatus.Errored
      void globalSnackbarStore.genericFetchError()
    }
  }

  async function fetchLibraryOauthSettings(libraryId: number): Promise<void> {
    try {
      const response = await LibraryOauthMethodAPI.fetch({ libraryId })
      const oauthSettings = (response.data as JsonAPIResource<LibraryOauthSettings>).attributes
      LibraryOauthSettings.value = oauthSettings
    } catch (e) {
      captureFetchException(e, { source: 'DashboardSettingsLibraryFetchLibraryOuathMethods' })
      libraryOauthMethodsStatus.value = LibrarySecurityStatus.Errored
      void globalSnackbarStore.genericFetchError()
    }
  }

  async function fetchLibraryGoogleAppLicensing(libraryId: number): Promise<void> {
    try {
      const response = await LibraryOauthMethodAPI.fetchGoogleAppLicensing({ libraryId })
      const googleAppLicensingSettings = (response.data as JsonAPIResource<GoogleAppLicensingSettingsResponse>)
        .attributes
      isLibraryUsingGoogleAppLicensing.value = googleAppLicensingSettings.enabled
      googleAppLicenseData.value.orderId = googleAppLicensingSettings.orderId ?? ''
      googleAppLicenseData.value.redeemUri = googleAppLicensingSettings.redeemUri ?? ''
    } catch (e) {
      captureFetchException(e, { source: 'DashboardSettingsLibraryFetchLibraryGoogleAppLicensing' })
      void globalSnackbarStore.genericFetchError()
    }
  }

  async function updateLibraryOauthMethod(
    oauthMethod: string,
    enable: boolean,
    thirdPartyIdInput?: string,
  ): Promise<void> {
    if (libraryId.value === null) {
      void globalSnackbarStore.setSnackbar({
        message: __('Error updating library login method'),
        notificationType: SnackbarNotificationType.error,
      })
      return
    }

    try {
      updateOauthMethodSettings(oauthMethod, enable)
      const response = await LibraryOauthMethodAPI.update({
        libraryId: libraryId.value,
        oauthMethod,
        enable,
        thirdPartyIdInput,
      })
      const oauthSettings = (response.data as JsonAPIResource<LibraryOauthSettings>).attributes
      LibraryOauthSettings.value = oauthSettings
    } catch (e) {
      updateOauthMethodSettings(oauthMethod, !enable)
      if (e instanceof FetchError) {
        try {
          const parsedErrorMessage = JSON.parse(e.message)
          const error = parsedErrorMessage.errors[0]

          if (e.status === HttpCode.UnprocessableEntity) {
            if (error.code === ApiErrorCode.LAST_OAUTH_PROVIDER) {
              void globalSnackbarStore.setSnackbar({
                message: __('Cannot disable only login option'),
                notificationType: SnackbarNotificationType.error,
              })
            }
          } else if (e.status === HttpCode.Conflict) {
            if (error.code === ApiErrorCode.CLEVER_DISTRICT_ID_ALREADY_IN_USE) {
              void globalSnackbarStore.setSnackbar({
                message: __('That Clever district id is already in use by another organization'),
                notificationType: SnackbarNotificationType.error,
              })
            } else if (error.code === ApiErrorCode.CLASSLINK_TENANT_ID_ALREADY_IN_USE) {
              void globalSnackbarStore.setSnackbar({
                message: __('That ClassLink tenant id is already in use by another organization'),
                notificationType: SnackbarNotificationType.error,
              })
            }
          } else {
            void globalSnackbarStore.genericFetchError()
          }
        } catch (jsonException) {
          void globalSnackbarStore.genericFetchError()
        }
        captureFetchException(e, { source: 'DashboardSettingsLibraryUpdateLibraryOauthMethod' })
      }
    }
  }

  function handleGoogleAppLicensingError(errorMessage: string): void {
    isGoogleAppLicenseLoading.value = false
    isLibraryUsingGoogleAppLicensing.value = false
    globalSnackbarStore.setSnackbar({
      message: errorMessage,
      notificationType: SnackbarNotificationType.error,
      timeout: 1500,
    })
  }

  function showConfirmationDialogueGoogleAppLicensing(): void {
    globalConfirmationDialogStore.openConfirmationDialog({
      title: __('Enable Google App Licensing?'),
      body: __(
        'Users in %{libraryName} will be signed out and will not be able to sign back in until you turn Google App Licensing off or assign them a license in the Google admin panel',
        { libraryName: libraryName.value },
      ),
      confirmButtonText: __('Confirm'),
      afterConfirmActions: [async () => await updateAllowGoogleAppLicensing(true)],
    })
  }

  async function updateAllowGoogleAppLicensing(useGoogleAppLicensing: boolean): Promise<void> {
    if (libraryId.value === null) {
      void globalSnackbarStore.setSnackbar({
        message: __('Error updating library login method'),
        notificationType: SnackbarNotificationType.error,
      })
      return
    }

    isLibraryUsingGoogleAppLicensing.value = useGoogleAppLicensing
    isGoogleAppLicenseLoading.value = true

    try {
      if (
        !useGoogleAppLicensing ||
        (googleAppLicenseData.value.orderId !== '' && googleAppLicenseData.value.redeemUri !== '')
      ) {
        /* const updateLibrarySettingsRes = */ await LibraryOauthMethodAPI.updateGoogleAppLicensing({
          libraryId: libraryId.value,
          enable: useGoogleAppLicensing,
          orderId: googleAppLicenseData.value.orderId,
          redeemUri: googleAppLicenseData.value.redeemUri,
        })
      } else {
        const licenseCreationRes = await GoogleAppLicensingAPI.createLicenses(libraryId.value)
        const licenseData = (licenseCreationRes.data as JsonAPIResource<GoogleAppLicensingCreateResponse>).attributes

        /* const updateLibrarySettingsRes = */ await LibraryOauthMethodAPI.updateGoogleAppLicensing({
          libraryId: libraryId.value,
          enable: useGoogleAppLicensing,
          orderId: licenseData.orderId ?? '',
          redeemUri: licenseData.redeemUri ?? '',
        })
        googleAppLicenseData.value = licenseData
      }
      useDashboardSettingsStore().fetchViewableLibraries()
    } catch (e) {
      handleGoogleAppLicensingError(__('Error setting up app licensing. Please try again.'))
      captureFetchException(e, { source: 'LibrarySecuritySettingsUpdateAllowGoogleAppLicensing' })
    } finally {
      isGoogleAppLicenseLoading.value = false
    }
  }

  function showInputDialogThirdPartyTenantId(oauthMethodName: LibraryOauthProvider): void {
    const inputDialogValues = getGlobalInputDialogValues(oauthMethodName)
    globalInputDialogStore.openInputDialog(inputDialogValues)
  }

  // helpers

  function updateOauthMethodSettings(oauthMethodName: string, enable: boolean): void {
    const updatedOauthMethods =
      libraryOauthMethods.value?.map((method) => {
        return method.name === oauthMethodName ? { name: method.name, enabled: enable } : method
      }) ?? []
    LibraryOauthSettings.value = { ...LibraryOauthSettings.value, oauth: updatedOauthMethods }
  }

  function getGlobalInputDialogValues(oauthMethod: LibraryOauthProvider): any {
    const cleverValues = {
      title: __('Add your Clever district ID'),
      subtitle: __(
        'This is available in your admin’s Clever Management Console. Read more here: <a id="link-external" href="%{cleverArticleUrl}" target="_blank" rel="noopener nofollow" class="oz-text-link-light-secondary dark:oz-text-link-dark-secondary break-all">%{cleverArticleUrl}</a>',
        {
          cleverArticleUrl: 'https://support.clever.com/hc/s/articles/000001500?language=en_US',
        },
      ),
      inputPlaceholder: __('E.g. %{tenantIdExample}', { tenantIdExample: 'AF5322XF' }),
      inputLabel: __('Clever district ID'),
      inputValue: cleverDistrictId.value ?? '',
      inputMaxLength: 25,
      inputAriaLabel: __('Enter your Clever district ID'),
      submitButtonText: __('Add'),
      submitActions: [
        ({ inputValue }): void => {
          void updateLibraryOauthMethod(oauthMethod, true, inputValue)
        },
      ],
      validationActions: [
        ({ inputValue }) => {
          const isContainsAlphaNumericOnly: boolean = /^[0-9a-zA-Z]*$/.test(inputValue)
          const validationFunction = isContainsAlphaNumericOnly

          globalInputDialogStore.setInputIsValid(validationFunction)
          globalInputDialogStore.setValidationMessage(validationFunction ? '' : __('Invalid district ID'))
          globalInputDialogStore.setSubmitButtonDisable(!validationFunction || inputValue.length === 0)
        },
      ],
    }

    const classlinkValues = {
      title: __('Add your ClassLink tenant ID'),
      subtitle: __(
        'This is available in your admin’s ClassLink Management Console. Read more here: <a id="link-external" href="%{classlinkArticleUrl}" target="_blank" rel="noopener nofollow" class="oz-text-link-light-secondary dark:oz-text-link-dark-secondary break-all">%{classlinkArticleUrl}</a>',
        {
          classlinkArticleUrl: 'https://help.classlink.com/s/article/Management-Console',
        },
      ),
      inputPlaceholder: __('E.g. %{tenantIdExample}', { tenantIdExample: 351800 }),
      inputLabel: __('ClassLink tenant ID'),
      inputValue: classlinkTenantId.value ?? '',
      inputMaxLength: 6,
      inputAriaLabel: __('Enter your ClassLink tenant ID'),
      submitButtonText: __('Add'),
      submitActions: [
        ({ inputValue }): void => {
          void updateLibraryOauthMethod(oauthMethod, true, inputValue)
        },
      ],
      validationActions: [
        ({ inputValue }) => {
          const isContainsZeroOrMoreNumbersOnly: boolean = /^[0-9]*$/.test(inputValue)
          const validationFunction = isContainsZeroOrMoreNumbersOnly

          globalInputDialogStore.setInputIsValid(validationFunction)
          globalInputDialogStore.setValidationMessage(validationFunction ? '' : __('Invalid tenant ID'))
          globalInputDialogStore.setSubmitButtonDisable(!validationFunction || inputValue.length === 0)
        },
      ],
    }

    return oauthMethod === LibraryOauthProvider.CLASSLINK ? classlinkValues : cleverValues
  }

  // SAML login

  const samlConnectionId = ref<Id | null>(null)
  const xSamlLoginSection = ref<boolean>(false)
  const samlSpEntityId = ref<string | null>(null) // It looks like a URL, e.g. https://padlet.dev/auth/saml/sp/2476480

  const hasSamlLoginRegistered = computed<boolean>(() => samlConnectionId.value != null)
  const libraryIdWithEpoch = computed<string>(() => {
    if (hasSamlLoginRegistered.value && samlSpEntityId.value === null) return ''
    // <library_id><epoch time in hours>
    return samlSpEntityId.value != null
      ? samlSpEntityId.value.substring(samlSpEntityId.value.lastIndexOf('/') + 1)
      : `${String(libraryId.value)}${Math.floor(Date.now() / 3600000)}`
  })
  const samlSPMetadataUrl = computed<string>(() => {
    // Before the launch of the SAML setup self-serve tool, most SAML connections did not have a samlSpEntityId,
    // and identity providers used https://padlet.com/auth/saml/metadata on their end. Refer to https://github.com/padlet/mozart/pull/16797.
    // After the launch, identity providers will use the URL format: https://padlet.com/auth/saml/metadata/<library_id><epoch time in hours>
    return `https://${NATIVE_HOST}/auth/saml/metadata/${libraryIdWithEpoch.value}`
  })

  function toggleSamlLoginSection(): void {
    xSamlLoginSection.value = !xSamlLoginSection.value
  }

  const samlIdpMetadataUrl = ref<string>('')
  const samlIdpMetadataUrlInput = ref<string>('')
  const samlIdpMetadataUrlValidationMessage = ref<string>('')
  const samlIdpMetadataUrlInputStatus = ref<InputStatus>(InputStatus.Viewing)

  function setSamlIdpMetadataUrl(payload: {
    input: string
    inputStatus: InputStatus
    validationMessage?: string
  }): void {
    samlIdpMetadataUrlInput.value = payload.input
    samlIdpMetadataUrlInputStatus.value = payload.inputStatus
    if (payload.validationMessage !== undefined) {
      samlIdpMetadataUrlValidationMessage.value = payload.validationMessage
    }
  }

  async function updateSamlIdpMetadataUrl(): Promise<void> {
    const trimmedSamlIdpMetadataUrl = samlIdpMetadataUrlInput.value.trim()

    if (trimmedSamlIdpMetadataUrl === '') {
      samlIdpMetadataUrlInputStatus.value = InputStatus.Errored
      samlIdpMetadataUrlValidationMessage.value = __('IdP metadata URL can not be blank')
      return
    }

    if (trimmedSamlIdpMetadataUrl === samlIdpMetadataUrl.value) {
      samlIdpMetadataUrlInputStatus.value = InputStatus.Viewing
      return
    }

    try {
      // TODO: Add API call to update SAML settings
    } catch (e) {
      globalSnackbarStore.setSnackbar({
        message: __('Error changing IdP metadata URL'),
        notificationType: SnackbarNotificationType.error,
      })
      captureFetchException(e, { source: 'LibraryInfoUpdateSamlIdpMetadataUrl' })
    } finally {
      samlIdpMetadataUrlInputStatus.value = InputStatus.Viewing
    }
  }

  const samlEmailAttribute = ref<string>('')
  const samlEmailAttributeInput = ref<string>('')
  const samlEmailAttributeValidationMessage = ref<string>('')
  const samlEmailAttributeInputStatus = ref<InputStatus>(InputStatus.Viewing)

  function setSamlEmailAttribute(payload: {
    input: string
    inputStatus: InputStatus
    validationMessage?: string
  }): void {
    samlEmailAttributeInput.value = payload.input
    samlEmailAttributeInputStatus.value = payload.inputStatus
    if (payload.validationMessage !== undefined) {
      samlEmailAttributeValidationMessage.value = payload.validationMessage
    }
  }

  async function updateSamlEmailAttribute(): Promise<void> {
    const trimmedSamlEmailAttribute = samlEmailAttributeInput.value.trim()

    if (trimmedSamlEmailAttribute === '') {
      samlEmailAttributeInputStatus.value = InputStatus.Errored
      samlEmailAttributeValidationMessage.value = __('Email attribute can not be blank')
      return
    }

    if (trimmedSamlEmailAttribute === samlEmailAttribute.value) {
      samlEmailAttributeInputStatus.value = InputStatus.Viewing
      return
    }

    try {
      await SamlApi.updateSamlConnection(
        samlConnectionId.value as Id,
        {
          emailAttribute: trimmedSamlEmailAttribute,
        },
        libraryId.value as Id,
      )
      samlEmailAttribute.value = trimmedSamlEmailAttribute
      samlEmailAttributeInput.value = trimmedSamlEmailAttribute
    } catch (e) {
      globalSnackbarStore.setSnackbar({
        message: __('Error changing email attribute.'),
        notificationType: SnackbarNotificationType.error,
      })
      captureFetchException(e, { source: 'LibraryInfoUpdateSamlEmailAttribute' })
    } finally {
      samlEmailAttributeInputStatus.value = InputStatus.Viewing
    }
  }

  const samlNameAttribute = ref<string>('')
  const samlNameAttributeInput = ref<string>('')
  const samlNameAttributeValidationMessage = ref<string>('')
  const samlNameAttributeInputStatus = ref<InputStatus>(InputStatus.Viewing)

  function setSamlNameAttribute(payload: {
    input: string
    inputStatus: InputStatus
    validationMessage?: string
  }): void {
    samlNameAttributeInput.value = payload.input
    samlNameAttributeInputStatus.value = payload.inputStatus
    if (payload.validationMessage !== undefined) {
      samlNameAttributeValidationMessage.value = payload.validationMessage
    }
  }

  async function updateSamlNameAttribute(): Promise<void> {
    const trimmedSamlNameAttribute = samlNameAttributeInput.value.trim()

    if (trimmedSamlNameAttribute === '') {
      samlNameAttributeInputStatus.value = InputStatus.Errored
      samlNameAttributeValidationMessage.value = __('Name attribute can not be blank')
      return
    }

    if (trimmedSamlNameAttribute === samlNameAttribute.value) {
      samlNameAttributeInputStatus.value = InputStatus.Viewing
      return
    }

    try {
      await SamlApi.updateSamlConnection(
        samlConnectionId.value as Id,
        { nameAttribute: trimmedSamlNameAttribute },
        libraryId.value as Id,
      )
      samlNameAttribute.value = trimmedSamlNameAttribute
      samlNameAttributeInput.value = trimmedSamlNameAttribute
    } catch (e) {
      globalSnackbarStore.setSnackbar({
        message: __('Error changing name attribute.'),
        notificationType: SnackbarNotificationType.error,
      })
      captureFetchException(e, { source: 'LibraryInfoUpdateSamlNameAttribute' })
    } finally {
      samlNameAttributeInputStatus.value = InputStatus.Viewing
    }
  }

  const samlRoleAttribute = ref<string>('')
  const samlRoleAttributeInput = ref<string>('')
  const samlRoleAttributeInputStatus = ref<InputStatus>(InputStatus.Viewing)

  function setSamlRoleAttribute(payload: { input: string; inputStatus: InputStatus }): void {
    samlRoleAttributeInput.value = payload.input
    samlRoleAttributeInputStatus.value = payload.inputStatus
  }

  async function updateSamlRoleAttribute(): Promise<void> {
    const trimmedSamlRoleAttribute = samlRoleAttributeInput.value.trim()

    if (trimmedSamlRoleAttribute === samlRoleAttribute.value) {
      samlRoleAttributeInputStatus.value = InputStatus.Viewing
      return
    }

    try {
      await SamlApi.updateSamlConnection(
        samlConnectionId.value as Id,
        { roleAttribute: trimmedSamlRoleAttribute },
        libraryId.value as Id,
      )
      samlRoleAttribute.value = trimmedSamlRoleAttribute
      samlRoleAttributeInput.value = trimmedSamlRoleAttribute
    } catch (e) {
      globalSnackbarStore.setSnackbar({
        message: __('Error changing role attribute.'),
        notificationType: SnackbarNotificationType.error,
      })
      captureFetchException(e, { source: 'LibraryInfoUpdateSamlRoleAttribute' })
    } finally {
      samlRoleAttributeInputStatus.value = InputStatus.Viewing
    }
  }

  const samlConnectionName = ref<string>('')
  const samlConnectionNameInput = ref<string>('')
  const samlConnectionNameInputStatus = ref<InputStatus>(InputStatus.Viewing)

  function setSamlConnectionName(payload: { input: string; inputStatus: InputStatus }): void {
    samlConnectionNameInput.value = payload.input
    samlConnectionNameInputStatus.value = payload.inputStatus
  }

  async function updateSamlConnectionName(): Promise<void> {
    const trimmedSamlConnectionName = samlConnectionNameInput.value.trim()

    if (trimmedSamlConnectionName === samlConnectionName.value) {
      samlConnectionNameInputStatus.value = InputStatus.Viewing
      return
    }

    try {
      await SamlApi.updateSamlConnection(
        samlConnectionId.value as Id,
        { connectionName: trimmedSamlConnectionName },
        libraryId.value as Id,
      )
      samlConnectionName.value = trimmedSamlConnectionName
      samlConnectionNameInput.value = trimmedSamlConnectionName
    } catch (e) {
      globalSnackbarStore.setSnackbar({
        message: __('Error changing login text.'),
        notificationType: SnackbarNotificationType.error,
      })
      captureFetchException(e, { source: 'LibraryInfoUpdateSamlConnectionName' })
    } finally {
      samlConnectionNameInputStatus.value = InputStatus.Viewing
    }
  }

  const isSamlDevModeEnabled = ref<boolean>(true)

  function setSamlDevMode(value: boolean): void {
    const title = value ? __('Enable Dev mode?') : __('Disable Dev mode?')
    const body = value
      ? __('Enabling dev mode will reset your login method to email only. Are you sure you want to proceed?')
      : __('Disabling dev mode will launch SAML login to all users. Are you sure you want to proceed?')
    globalConfirmationDialogStore.openConfirmationDialog({
      ...ALERT_ICON,
      title,
      body,
      confirmButtonText: __('Change'),
      cancelButtonText: __('Nevermind'),
      forceFullWidthButtons: true,
      afterConfirmActions: [async () => await updateSamlDevMode(value)],
    })
  }

  async function updateSamlDevMode(isEnabled: boolean): Promise<void> {
    try {
      isSamlDevModeEnabled.value = isEnabled
      await SamlApi.updateSamlConnection(samlConnectionId.value as Id, { devMode: isEnabled }, libraryId.value as Id)
    } catch (e) {
      isSamlDevModeEnabled.value = !isEnabled

      globalSnackbarStore.setSnackbar({
        message: __('Error updating dev mode.'),
        notificationType: SnackbarNotificationType.error,
      })
      captureFetchException(e, { source: 'LibraryInfoUpdateSamlDevMode' })
    }
  }

  async function fetchSamlLoginInfo(): Promise<void> {
    try {
      const response = await SamlApi.fetchSamlConnections(libraryId.value as Id)
      const samlConnections = response.data as Array<JsonApiData<SamlConnectionApiResponse>>

      if (samlConnections.length !== 0) {
        // A backpack library can have multiple SAML connections, but we only support one for now
        const samlConnection = samlConnections[0].attributes

        samlConnectionId.value = samlConnection.id
        samlIdpMetadataUrl.value = samlConnection.idpMetadataUrl
        samlEmailAttribute.value = samlConnection.emailAttribute
        samlEmailAttributeInput.value = samlConnection.emailAttribute
        samlNameAttribute.value = samlConnection.nameAttribute
        samlNameAttributeInput.value = samlConnection.nameAttribute
        samlRoleAttribute.value = samlConnection.roleAttribute
        samlRoleAttributeInput.value = samlConnection.roleAttribute
        samlConnectionName.value = samlConnection.connectionName
        samlConnectionNameInput.value = samlConnection.connectionName
        isSamlDevModeEnabled.value = samlConnection.devMode
        samlSpEntityId.value = samlConnection.spEntityId
        xSamlLoginSection.value = true
      } else {
        samlConnectionName.value = 'SAML'
        samlConnectionNameInput.value = 'SAML'
        samlIdpMetadataUrlValidationMessage.value = __('IdP metadata URL can not be blank')
        samlIdpMetadataUrlInputStatus.value = InputStatus.Errored
        samlEmailAttributeValidationMessage.value = __('Email attribute can not be blank')
        samlEmailAttributeInputStatus.value = InputStatus.Errored
        samlNameAttributeValidationMessage.value = __('Name attribute can not be blank')
        samlNameAttributeInputStatus.value = InputStatus.Errored
      }
    } catch (e) {
      globalSnackbarStore.setSnackbar({
        message: __('Error fetching SAML login info'),
        notificationType: SnackbarNotificationType.error,
      })
      captureFetchException(e, { source: 'LibraryInfoFetchSamlLoginInfo' })
    }
  }

  const isAwaitingCreateSamlConnectionResponse = ref<boolean>(false)

  async function createSamlConnection(): Promise<void> {
    const trimmedSamlIdpMetadataUrl = samlIdpMetadataUrlInput.value.trim()

    if (trimmedSamlIdpMetadataUrl === '') {
      samlIdpMetadataUrlInputStatus.value = InputStatus.Errored
      samlIdpMetadataUrlValidationMessage.value = __('IdP metadata URL can not be blank')
      return
    }

    if (trimmedSamlIdpMetadataUrl === samlIdpMetadataUrl.value) {
      samlIdpMetadataUrlInputStatus.value = InputStatus.Viewing
      return
    }

    const trimmedSamlEmailAttribute = samlEmailAttributeInput.value.trim()

    if (trimmedSamlEmailAttribute === '') {
      samlEmailAttributeInputStatus.value = InputStatus.Errored
      samlEmailAttributeValidationMessage.value = __('Email attribute can not be blank')
      return
    }

    if (trimmedSamlEmailAttribute === samlEmailAttribute.value) {
      samlEmailAttributeInputStatus.value = InputStatus.Viewing
      return
    }

    const trimmedSamlNameAttribute = samlNameAttributeInput.value.trim()

    if (trimmedSamlNameAttribute === '') {
      samlNameAttributeInputStatus.value = InputStatus.Errored
      samlNameAttributeValidationMessage.value = __('Name attribute can not be blank')
      return
    }

    if (trimmedSamlNameAttribute === samlNameAttribute.value) {
      samlNameAttributeInputStatus.value = InputStatus.Viewing
      return
    }

    try {
      isAwaitingCreateSamlConnectionResponse.value = true
      const response = await SamlApi.createSamlConnection(
        {
          idpMetadataUrl: trimmedSamlIdpMetadataUrl,
          emailAttribute: trimmedSamlEmailAttribute,
          nameAttribute: trimmedSamlNameAttribute,
          roleAttribute: samlRoleAttributeInput.value.trim(),
          connectionName: samlConnectionNameInput.value.trim() !== '' ? samlConnectionNameInput.value.trim() : 'SAML',
          devMode: isSamlDevModeEnabled.value,
          prespecifiedSpEntityId: `https://${NATIVE_HOST}/auth/saml/sp/${libraryIdWithEpoch.value}`,
        },
        libraryId.value as Id,
      )
      const samlConnection = (response.data as Array<JsonApiData<SamlConnectionApiResponse>>)[0].attributes

      samlConnectionId.value = samlConnection.id
      samlIdpMetadataUrl.value = samlConnection.idpMetadataUrl
      samlEmailAttribute.value = samlConnection.emailAttribute
      samlEmailAttributeInput.value = samlConnection.emailAttribute
      samlNameAttribute.value = samlConnection.nameAttribute
      samlNameAttributeInput.value = samlConnection.nameAttribute
      samlRoleAttribute.value = samlConnection.roleAttribute
      samlRoleAttributeInput.value = samlConnection.roleAttribute
      samlConnectionName.value = samlConnection.connectionName
      samlConnectionNameInput.value = samlConnection.connectionName
      isSamlDevModeEnabled.value = samlConnection.devMode

      globalSnackbarStore.setSnackbar({
        message: __('SAML connection created.'),
        notificationType: SnackbarNotificationType.success,
      })
    } catch (e) {
      globalSnackbarStore.setSnackbar({
        message: __('Error creating SAML connection'),
        notificationType: SnackbarNotificationType.error,
      })
      captureFetchException(e, { source: 'LibraryInfoCreateSamlConnection' })
    } finally {
      isAwaitingCreateSamlConnectionResponse.value = false
    }
  }

  return {
    // State
    libraryUserEmailDomains,
    libraryUserEmailDomainStatus,
    isLibraryUsingGoogleAppLicensing,
    isGoogleAppLicenseLoading,
    googleAppLicenseData,

    // Getters
    isLoadingLibrarySecurity,
    libraryOauthMethods,
    canDisableOauthMethods,
    cleverDistrictId,
    classlinkTenantId,
    xLibraryUserEmailDomains,
    hasSamlLoginRegistered,
    samlSPMetadataUrl,
    xSamlLoginSection,
    isAwaitingCreateSamlConnectionResponse,

    samlIdpMetadataUrl,
    samlIdpMetadataUrlInput,
    samlIdpMetadataUrlValidationMessage,
    samlIdpMetadataUrlInputStatus,

    samlEmailAttribute,
    samlEmailAttributeInput,
    samlEmailAttributeValidationMessage,
    samlEmailAttributeInputStatus,

    samlNameAttribute,
    samlNameAttributeInput,
    samlNameAttributeValidationMessage,
    samlNameAttributeInputStatus,

    samlRoleAttribute,
    samlRoleAttributeInput,
    samlRoleAttributeInputStatus,

    samlConnectionName,
    samlConnectionNameInput,
    samlConnectionNameInputStatus,

    isSamlDevModeEnabled,

    // Actions
    initialize,
    updateLibraryOauthMethod,
    updateAllowGoogleAppLicensing,
    showInputDialogThirdPartyTenantId,
    showConfirmationDialogueGoogleAppLicensing,
    createSamlConnection,
    toggleSamlLoginSection,
    setSamlIdpMetadataUrl,
    updateSamlIdpMetadataUrl,
    setSamlEmailAttribute,
    updateSamlEmailAttribute,
    setSamlNameAttribute,
    updateSamlNameAttribute,
    setSamlRoleAttribute,
    updateSamlRoleAttribute,
    setSamlConnectionName,
    updateSamlConnectionName,
    setSamlDevMode,
    updateSamlDevMode,
  }
})
